<template>
  <div>
    <v-dialog
      v-model="showReceiptInvoiceDialog"
      @click:outside="$emit('update:showReceiptInvoiceDialog', false);"
      max-width="80%"
    >
      <v-card>
        <v-card-title class="pa-2">
          <v-spacer />
          <v-btn
            color="primary"
            small
            :loading="loading"
            :disabled="model.grand_total !== '0.00'"
            @click="updateInvoiceForReceipt"
          >
            {{$t('receipt.save')}}
          </v-btn>
          <v-btn
            icon
            @click="$emit('update:showReceiptInvoiceDialog', false);"
          >
            <v-icon color="primary darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-h5 px-4">
          <v-form
            ref="formInvoice"
            v-model="validInvoice"
            lazy-validation
            :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
          >
            <Alert :alert.sync="alert" :message="message" />
            <v-row>
              <v-col cols="12">
                <v-card
                  class="mx-auto"
                  flat
                >
                  <v-card-title class="py-1 primary--text">
                    <v-icon color="primary">mdi-cart-plus</v-icon>
                    <h6 class="ms-2 align-self-center font-weight-regular">{{$t('receiptTax.addReceipt')}}</h6>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row :dense="true">
                      <v-col cols="12">
                        <v-simple-table
                          dense
                          class="custom-table elevation-1 item-table"
                          fixed-header
                          height="250"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th :style="{width: '40px'}">
                                  {{ $t('receipt.no') }}
                                </th>
                                <th :style="{width: '120px'}">
                                  {{ $t('receipt.field.paymentDate') }}
                                </th>
                                <th :style="{width: '140px'}">
                                  {{ $t('receipt.paymentType') }}
                                </th>
                                <th :style="{width: '130px'}">
                                  {{ $t('receipt.chequeNumber') }}
                                </th>
                                <th :style="{width: '130px'}">
                                  {{ $t('receipt.bankNo') }}
                                </th>
                                <th :style="{width: '130px'}">
                                  {{ $t('receipt.branch') }}
                                </th>
                                <th :style="{width: '100px'}">
                                  {{ $t('receipt.accountNo') }}
                                </th>
                                <th :style="{width: '100px'}">
                                  {{ $t('receipt.amount') }}
                                </th>
                                <th class="text-center" :style="{width: '100px'}">
                                  {{ $t('receipt.action') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in paymentItems" :key="index" :class="index+1 == paymentItems.length ? 'newRow' : ''">
                                <td>
                                  {{index+1}}
                                </td>
                                <td>
                                  <v-menu
                                    :disabled="saveButtonDisable"
                                    :ref="`startDateMenu${index}`"
                                    v-model="item.startDateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="item.receipt_tax_invoice_payment_date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        class="date-input"
                                        hide-details="auto"
                                        dense
                                        :disabled="saveButtonDisable"
                                        v-model="processedPaymentItems[index].formattedPaymentDate"
                                        :rules="paymentDateValid"
                                        required
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="item.receipt_tax_invoice_payment_date"
                                      @change="$refs[`startDateMenu${index}`][0].save(item.receipt_tax_invoice_payment_date)"
                                    >
                                      <v-btn small class="primary" @click="$refs[`startDateMenu${index}`][0].save(todayDate())">{{ $t('route.today') }}</v-btn>
                                    </v-date-picker>
                                  </v-menu>
                                </td>
                                <td>
                                  <v-select
                                    :disabled="saveButtonDisable"
                                    dense
                                    hide-details="auto"
                                    v-model="item.payment_terms_id"
                                    :items="paymentGroupValue"
                                    item-text="description"
                                    item-value="id"
                                    return-object
                                    :rules="paymentTypeValid"
                                    required
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    @change="paymentTypeChange(item, index)"
                                  >
                                    <template v-slot:append-outer>
                                      <v-icon color="primary" dense v-if="item.payment_terms_id &&  (item.payment_terms_id.description === 'Bank transfer' || item.payment_terms_id.description === 'העברה')" @click="item.payment_terms_id ? item.payment_terms_id.description === 'Bank transfer' || item.payment_terms_id.description === 'העברה' ? openBankDialog(index) : '' : ''">
                                        mdi-bank
                                      </v-icon>
                                    </template>
                                  </v-select>
                                </td>
                                <td>
                                  <v-text-field
                                    :disabled="saveButtonDisable"
                                    :ref="'chequeRef'+index"
                                    dense
                                    v-model="item.cheque_no"
                                    hide-details="auto"
                                    :rules="paymentTypeArray.includes(item.payment_terms_id ? item.payment_terms_id.description : '') ? chequeNumberValid : []"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    :disabled="saveButtonDisable"
                                    :ref="'bankRef'+index"
                                    dense
                                    v-model="item.bank"
                                    hide-details="auto"
                                    :rules="paymentTypeArray.includes(item.payment_terms_id ? item.payment_terms_id.description : '') ?bankNoValid : []"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    :disabled="saveButtonDisable"
                                    :ref="'branchRef'+index"
                                    dense
                                    v-model="item.branch"
                                    hide-details="auto"
                                    :rules="paymentTypeArray.includes(item.payment_terms_id ? item.payment_terms_id.description : '') ?branchValid : []"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    :disabled="saveButtonDisable"
                                    :ref="'accountNoRef'+index"
                                    dense
                                    v-model="item.account_no"
                                    hide-details="auto"
                                    :rules="paymentTypeArray.includes(item.payment_terms_id ? item.payment_terms_id.description : '') ?accountNoValid : []"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    :disabled="saveButtonDisable"
                                    :ref="'amount'+index"
                                    dense
                                    v-model="item.amount"
                                    hide-details="auto"
                                    :rules="amountValid"
                                    @change="handleInput"
                                    required
                                  />
                                </td>
                                <td class="text-center">
                                  <v-row>
                                    <v-col cols="6" class="text-start">
                                      <v-btn
                                        icon
                                        color="error"
                                        @click="addRow"
                                        small
                                        :disabled="saveButtonDisable || (accessRight.length < 2 && accessRight.includes('show'))"
                                      >
                                        <v-icon color="error">mdi-plus</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="6" class="text-start">
                                      <v-btn
                                        v-if="paymentItems.length !== 1"
                                        icon
                                        color="error"
                                        small
                                        @click="deleteRow(index)"
                                        :disabled="saveButtonDisable || (accessRight.length < 2 && accessRight.includes('show'))"
                                      >
                                        <v-icon color="error">mdi-delete-sweep-outline</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                    <v-row :dense="true" class="pt-6">
                      <v-col cols="7">
                      </v-col>
                      <v-divider class="mx-6" vertical/>
                      <v-col cols="4">
                        <v-row dense>
                          <v-col cols="6" class="font-weight-bold">
                            {{$t('receipt.field.grandTotal')}}
                          </v-col>
                          <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            <v-text-field
                              height="18"
                              dense
                              readonly
                              :reverse="locale === 'he' ? false : true"
                              hide-details="auto"
                              v-model="model.grand_total"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-overlay v-if="isProgressCircular">
                <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
              </v-overlay>
              <BankDialog @onBankChange="onBankChange" :dialog.sync="bankDialog" />
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Alert from "@/components/Alert"
import {formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate} from '@/utils';
import {getAuxiliaryZtableValueByName} from '@/utils';
import BankDialog from '@/components/moduleTableDialog/Dialog/BankDialog';

export default {
  name: "ReceiptInvoices",
  props: ["showReceiptInvoiceDialog", "receiptInvoicesModel", "customerId", "grandTotal", "paidTotal", "receiptInvoicesType", "updatedDocuments", "receiptId", "updatedButton", "receiptTaxInvoiceId", "modelData"],
  components: {
    Alert,
    BankDialog
  },
  data() {
    return {
      alert: false,
      loading: false,
      validInvoice: false,
      model: {
        grand_total: 0.00
      },
      message: '',
      paymentItems: [{
        bank: null,
        branch: null,
        account_no: null,
        amount: null,
        receipt_tax_invoice_payment_date: new Date().toISOString().substring(0,10)
      }],
      startDateMenu: false,
      processedPaymentItems:[{formattedPaymentDate:'',receipt_tax_invoice_payment_date:''}],
      paymentTypeArray: ['Cheque', 'המחאה'],
      cashPaymentArray: ['Cash', 'מזומן'],
      paymentGroupValue: [],
      bankDialog: false,
      bankDialogIndex: null,
      formDataCopy: [],
      propsTotal: this.grandTotal,
      isProgressCircular: false,
    }
  },
  async mounted() {
    this.isProgressCircular = true;
    let tableNameValue = await getAuxiliaryZtableValueByName(8);
    if(Object.keys(tableNameValue).length > 0) {
      this.paymentGroupValue = tableNameValue.ztables.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 : -1);
    }
    if (this.receiptTaxInvoiceId) {
      this.isProgressCircular = true;
      if (this.modelData.receiptTaxInvoicePayments.length) {
        this.paymentItems = JSON.parse(JSON.stringify(this.modelData.receiptTaxInvoicePayments))
      }
      this.isProgressCircular = false;
    }
    this.isProgressCircular = false;
    this.model.grand_total = this.numberWithCommas(this.grandTotal)
    this.handleInput();
  },
  computed: {
    saveButtonDisable() {
      return this.receiptInvoicesType === 'showReceiptFromMultiple' || this.modelData.status === 0 ? false : true
    },
    paymentDateValid() {
      return [
        v => !!v || this.$t('formRules.paymentDateRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.paymentDateRequired')
      ];
    },
    paymentTypeValid() {
      return [
        v => !!v || this.$t('formRules.paymentTypeRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.paymentTypeRequired')
      ];
    },
    chequeNumberValid() {
      return [
        v => !!v || this.$t('formRules.chequeNumberRequired'),
        v => !v || (v && v.length <= 20) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.chequeNumber'), type: this.$t('common.less'), size: 20 }),
      ];
    },
    bankNoValid() {
      return [
        v => !!v || this.$t('formRules.bankNoRequired'),
        v => !v || (v && v.length <= 20) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.bankNo'), type: this.$t('common.less'), size: 20 }),
      ];
    },
    branchValid() {
      return [
        v => !!v || this.$t('formRules.branchRequired'),
        v => !v || (v && v.length <= 20) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.branch'), type: this.$t('common.less'), size: 20 }),
      ];
    },
    accountNoValid() {
      return [
        v => !!v || this.$t('formRules.accountNoRequired'),
        v => !v || (v && v.length <= 25) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.accountNo'), type: this.$t('common.less'), size: 25 }),
      ];
    },
    amountValid() {
      return [
        v => !!v || this.$t('formRules.amountRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.amountRequired'),
        v => !v || /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) || this.$t('formRules.spaceValidValue'),
        v => !v || (v && String(v).length <= 12) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.amount'), type: this.$t('common.less'), size: 12 }),
      ];
    },
    ...mapGetters({
      locale: 'locale',
      profileById: 'profile/profileById',
      accessRight: "accessRight"
    }),
  },
  watch: {
    paymentItems:{
      handler(val){
        this.processedPaymentItems = val.map(e=>({...e,formattedPaymentDate:this.profileById.date_format ? formatDateDDMMYYYY(e.receipt_tax_invoice_payment_date) :e.receipt_tax_invoice_payment_date}))
      },
      deep:true
    },
    profileById(){
      this.processedPaymentItems = this.paymentItems.map(e=>({...e,formattedPaymentDate:this.profileById.date_format ?formatDateDDMMYYYY(e.receipt_tax_invoice_payment_date) :e.receipt_tax_invoice_payment_date}))
    },
    async showReceiptInvoiceDialog() {
      if (this.receiptTaxInvoiceId) {
        this.isProgressCircular = true;
        this.paymentItems = this.modelData.receiptTaxInvoicePayments
        this.isProgressCircular = false;
      }
    }
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    todayDate: todayDate,
    handleInput() {
      if (this.paymentItems && this.paymentItems.length > 0) {
        let self = this;
        this.model.grand_total = this.paymentItems.length > 0 ? this.paymentItems.reduce(function(amount, item) {
          const itemAmount = parseFloat(self.numberFromCommas(item.amount)) || 0;
          return parseFloat(amount) - itemAmount;
        }, self.propsTotal).toFixed(2) : 0;
        this.model.grand_total = Number(this.model.grand_total).toFixed(2);
      }
    },
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : x;
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, '') : null;
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return !v || (v && String(v).length <= sizeValue) || this.$t('formRules.lengthValidMessage', { fieldName: fieldValue, type: typeValue, size: sizeValue });
    },
    openBankDialog(index) {
      this.bankDialog = true;
      this.bankDialogIndex = index;
    },
    async onBankChange(bank) {
      if (bank && this.bankDialogIndex >= 0) {
        this.paymentItems[this.bankDialogIndex].bank = bank.name;
        this.paymentItems[this.bankDialogIndex].branch = bank.branch_name;
        this.paymentItems[this.bankDialogIndex].account_no = bank.account_no;
      }
    },
    async paymentTypeChange(item, index) {
      if (this.paymentTypeArray.includes(item.payment_terms_id.description)) {
        await this.$refs['bankRef'+index][0].onFocus()
        await this.$refs['branchRef'+index][0].onFocus()
        await this.$refs['accountNoRef'+index][0].onFocus()
        await this.$refs['chequeRef'+index][0].onFocus()
        await this.$refs['chequeRef'+index][0].onBlur()
      } else if (this.cashPaymentArray.includes(item.payment_terms_id.description)) {
        this.$refs['amount'+index][0].onFocus()
      }
    },
    addRow() {
      this.paymentItems.push({
        bank: null,
        branch: null,
        account_no: null,
        amount: null,
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(".item-table div.v-data-table__wrapper")
      this.$vuetify.goTo(newRow, {container: wrapper});
    },
    deleteRow(index) {
      this.paymentItems.splice(index,1);
      this.handleInput()
    },
    async updateInvoiceForReceipt() {
      if(this.$refs.formInvoice.validate()){
        this.loading = true;
        const modifiedArray = this.paymentItems.map(obj => {
          const {payment_terms_id, startDateMenu, amount, ...rest} = obj;
          const currentYear = new Date().getFullYear().toString();
          const numberAmount = Number(amount)
          console.log(startDateMenu, "startDateMenu")
          return {
            ...rest,
            receipt_tax_invoice_payment_year: currentYear,
            amount: numberAmount,
            payment_terms_id: payment_terms_id.id,
            is_form_disable: true
          }
        })
        this.formDataCopy = [...modifiedArray]
        this.$emit('dataUpdated', this.formDataCopy);
        this.loading = false;
        this.$emit('update:showReceiptInvoiceDialog', false);
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.date-input ::v-deep input  {
  font-size: 12px;
}
.date-input ::v-deep .mdi-close  {
  font-size: 16px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>